const portfolio = [
  {
    id: 'astrapay',
    companyName: 'AstraPay',
    type: 'talent',
    title: 'Sourcing Full-stack developers, Data team, and Product Owner team - AstraPay',
    content: [
      'AstraPay (part of the Astra Group) is a mobile-based electronic money service developed by PT Astra Digital Arta. It functions as an E-wallet App enabling both individuals or businesses to make daily financial transactions online and hassle-free.',
      "As AstraPay geared up for further growth, G2Academy was brought onboard to help source manpower that could drive and support impactful change for the company.  We provided a continuous pipeline of qualified digital talents, consultants and experts, all of whom were placed within AstraPay's internal Full-stack development, data and product owner team."
    ]
  },
  {
    id: 'astrapay',
    companyName: 'AstraPay',
    type: 'training',
    title: 'Product & Technology Development Program - AstraPay',
    content: [
      'AstraPay (part of the Astra Group) is an E-wallet app designed to help  individuals and businesses carry out daily financial transactions with ease. From personal bill payments, instalments, and mobile credit top-ups to receiving payments, optimizing online transactions and insights for businesses.',
      "As a corporate training partner, G2Academy accelerated AstraPay's initiative to empower their freshly hired developers through a Product & Technology Development Program. With several batches in, we have created a Full-stack Bootcamp curriculum customized to instil the hard and soft skills needed to become versatile full-stack developers."
    ]
  },
  {
    id: 'astrapay',
    companyName: 'AstraPay',
    type: 'project',
    title: 'Data Lake & Data Warehouse Project - AstraPay',
    content: [
      'AstraPay (part of the Astra Group) is a mobile-based E-wallet App that  allows users to transfer or receive funds online with ease. Individuals can make transactions for activities such as, bill payments, instalments, mobile credit top-up, and many more. Registered merchants have the convenience of optimizing business transactions, receiving payments and access to exclusive data insights.',
      'G2Academy was chosen by AstraPay to lead a project in the development of a Data Lake & Data Warehouse. Essentially managing large volumes of raw data that were originally unprocessed or unspecified, into structured and filtered data with a specific purpose within a warehouse. Effectively creating a more efficient, stable and centralized repository for their large amounts of data.'
    ]
  },
  {
    id: 'btpns',
    companyName: 'BTPNS',
    type: 'talent',
    title: 'Manpower for Back-end (Golang + Java) & QA Teams - BTPN Syariah',
    content: [
      'BTPN Syariah is a Shariah Commercial Bank in Indonesia, primarily focused on providing services that empower the inclusive communities of their customers and developing financial inclusion. With the rise and evolution of technology, BTPN Syariah is eager to leverage its benefits to bring better convenience and efficiency for both internal and external stakeholders.',
      "With that in mind, G2Academy was brought onboard to help provide the necessary manpower, consultation, and expertise needed for the company's internal development projects.  This includes pipelining a range of highly-qualified senior, mid, and junior developers for their Back-end and Quality Assurance teams."
    ]
  },
  {
    id: 'btpns',
    companyName: 'BTPNS',
    type: 'training',
    title: 'ShiftED Program - BTPN Syariah',
    content: [
      'BTPN Syariah is a Shariah Commercial Bank with an intent of bringing products or services that adds value, convenience and a positive impact in the lives of their customers. In this venture, they are focused on leveraging industry-relevant technologies to help deliver better outcomes, support and financial services for their customers.',
      "As part of this development, BTPN Syariah and G2Academy collaborated to bring the ShiftED (Sharia Intensive Full-stack Engineer Development)  Program. An initiative that involves developing a personalized 4-month Full-stack Bootcamp for newly recruited developers within BTPN Syariah. Withi multiple batches already in place, the curriculum focuses on building the technical and soft skills required to become an adept full-stack developer in today's digital era."
    ]
  },
  {
    id: 'btpns',
    companyName: 'BTPNS',
    type: 'project',
    title: 'HRIS Integral App Project - BTPN Syariah',
    content: [
      'As a Shariah Commercial Bank in Indonesia, BTPN Syariah is committed to delivering products and services that empower inclusive communities of their customers and develop financial inclusion. With technology at the forefront of their development, new innovations and growth opportunities are always encouraged.',
      'In this context, BTPN Syariah and G2Academy teamed up in a technology project to create a HRIS Integral App. It is a Human Resource Information System invented to implement structured data and combine processes into one simple platform.'
    ]
  },
  {
    id: 'xlaxiata',
    companyName: 'XL Axiata',
    type: 'training',
    title: 'GoLang Microservice Corporate Training - XL Axiata',
    content: [
      'XL Axiata is an Indonesia-based mobile telecommunications services operator with extensive coverage nationwide. Keeping up with customer expectations of high-quality services involves staying ahead of technological innovations and trends.',
      'Ensuring that employees retain the most relevant skills is an important aspect of this, as it affects the quality of products and services delivered. As a corporate training partner, XL Axiata placed their trust in G2Academy to deliver a Golang-centric training curriculum for their internal developers. Further updating and expanding their knowledge base in a fast-paced technology environment.'
    ]
  },
  {
    id: 'dana',
    companyName: 'DANA',
    type: 'talent',
    title: 'Manpower For Team Mentor - DANA',
    content: [
      'DANA stands as an E-Wallet provider with a vision of creating a cashless society. Their app is a one-stop solution where users can easily make daily financial transactions. From transfering money and purchasing in DANA merchants, to topping up mobile credit and paying bills.',
      'As their talent pipeline partner, G2Academy helped in sourcing experts to lead and mentor those that graduated from the DANA Prentech program. Onboarding them into various small projects at DANA.'
    ]
  },
  {
    id: 'dana',
    companyName: 'DANA',
    type: 'training',
    title: 'DANA Prentech (Bootcamp Java, Android, iOS, SDET) Corporate Training - DANA',
    content: [
      'DANA is an Indonesian digital wallet with a vision of creating a cashless society. Their app serves as a one-stop solution where users can easily transact on daily activities. From transfering money and purchasing in DANA merchants, to topping up mobile credit and paying bills.',
      'With the intention of accelerating their vision, DANA chose G2Academy as a corporate training partner to enhance the personal and professional development of their employees. We constructed a 4-month bootcamp training program specifically focusing on Java, Android, iOS, and SDET best practices, while also incorporating the soft skills development into the curriculum. All crucial facets of a well-rounded Full-stack developer.'
    ]
  },
  {
    id: 'telkomsat',
    companyName: 'TELKOMSAT',
    type: 'talent',
    title: 'Manpower For Data Science and Data Engineering Teams - Telkomsat',
    content: [
      'Telkomsat functions as a subsidiary of Telkom, a satellite service provider with an expertise in offering high-quality and internationally acclaimed upstream to downstream services. Supplying a range of telecommunications and technology solutions to corporations across various industries.',
      'In bolstering their own vision of becoming the leading satellite provider in the region, Telkomsat is proactive in hiring skilled talents to produce the best outcome for their company. By partnering up with G2Academy, Telkomsat has placed their trust in us as consultants to help pipeline the required manpower for their growing Data Science and Data Engineering teams. Sourcing and maintaining relations with highly qualified candidates that could thrive and grow with the company.'
    ]
  },
  {
    id: 'telkomsat',
    companyName: 'TELKOMSAT',
    type: 'training',
    title: 'Data Warehouse & UI/UX Training - Telkomsat',
    content: [
      'Telkomsat is a subsidiary of Telkom, a satellite service provider that brings high-quality and international standard upstream to downstream services. Offering a great variety of telecommunications and technology solutions to various industry sectors nationwide.',
      "As their corporate training partner, G2Academy developed as well as implemented a customized program aimed at instilling Data Warehousing and UI/UX best practices to Telkomsat's internal developers."
    ]
  },
  {
    id: 'telkomsat',
    companyName: 'TELKOMSAT',
    type: 'project',
    title: 'Data Warehouse & Aplikasi CRM 3Easy Project - Telkomsat',
    content: [
      'Telkomsat serves as a subsidiary of Telkom, a satellite service provider known for having high-quality and international standard upstream to downstream services. They supply in-demand telecommunications and technology solutions to various industry sectors nationwide.',
      "G2Academy and Telkomsat carried out a project to structure and filter data into a warehouse. Enabling it to be processed and aligned with the company's goals for developing a customer relation management application."
    ]
  },
  {
    id: 'megalife',
    companyName: 'Megalife',
    type: 'training',
    title: 'Advanced Talend Training - PFI Mega Life',
    content: [
      'PFI Mega Life is a structured joint venture life insurance company that merges the global expertise of Prudential Financial Inc with the local market network of CT Corpora. They offer a diverse array of life insurance products which caters to both business entities and individuals nationwide.',
      'As a corporate training to PFI Mega Life, G2Academy organized an advanced Talend training program for their internal team of developers. Implementing a curriculum designed to boost professional growth and development.'
    ]
  },
  {
    id: 'bankbri',
    companyName: 'Bank BRI',
    type: 'training',
    title: 'GoLang Microservice, DevOps Kubernetes, PWA with VueJs Training - Bank BRI',
    content: [
      "Bank BRI is one of the leading Indonesian commercial banks and the country's largest lender by assets. Their business primarily focuses on banking services in micro, small, and medium enterprises (MSME's).",
      'By prioritizing career and professional growth for their employees, Bank BRI collaborated with G2Academy for an extensive corporate training targeting skill development in GoLang Microservice, and DevOps Kubernetes, PWA with VueJs.  Further expanding the knowledge base and growth potential of their developers.'
    ]
  },
  {
    id: 'bankbri',
    companyName: 'Bank BRI',
    type: 'project',
    title: 'New Delivery System Application - Bank BRI',
    content: [
      'BRI is one of the largest banks in Indonesia and has become a pioneer of microfinance across the nation. Their business activities revolve around banking services for Micro, Small and Medium Enterprises (MSME).',
      "In a collective technology project, G2academy was selected to help change BRI's core banking system. Starting with applications and systems that would go live in all BRI branches across Indonesia.",
      'G2Academy developed a New Delivery System that supports product delivery, along with anticipating competition within the banking and retail industry.'
    ]
  },
  {
    id: 'bankjatim',
    companyName: 'Bank Jatim',
    type: 'training',
    title: 'ReactJS & ReactNative Training - Bank Jatim',
    content: [
      'Bank Jatim is an Indonesian bank intent on encouraging and contributing to regional economic growth of East Java by being involved in the development of small and medium credit business sectors and implementing sustainable finance principles.',
      'Within the digital area,  growth comes hand-in-hand with technological changes. To maintain continuous improvements in their developers, Bank Jatim opted for a specialized corporate training program with G2Academy. The curriculum revolved around skill upgrades in two primary areas for their developers; ReactJS and React Native.'
    ]
  },
  {
    id: 'faspay',
    companyName: 'Faspay',
    type: 'training',
    title: 'GoLang Training - Faspay',
    content: [
      'Faspay is an award-winning payment gateway with complete payment channels across Indonesia. Their services aim to help businesses, from startups, MSMEs to corporations, accept online payments, send funds, and promote business growth.',
      'In prioritizing efficiency and consistent technological expansion, Faspay sought to further improve the knowledge base of their own developers. Eventually selecting G2Academy as a corporate training partner to implement a comprehensive yet flexible GoLang curriculum for their team.'
    ]
  },
  {
    id: 'danamas',
    companyName: 'Danamas',
    type: 'talent',
    title: 'Manpower for Data Analyst & Data Engineering team - Danamas',
    content: [
      'Danamas is a peer-to-peer lending platform that serves as an online community for investing in small business debt, and enables borrowers to receive funding directly from investors.',
      'As Danamas sought to continuously leverage the growth of technology, they teamed up with G2Academy to help fulfil their manpower requirements. We provided a talent pipeline service that resulted in the recruitment of exceptional candidates for their Data Analyst and Data Engineering teams.'
    ]
  },
  {
    id: 'gotoko',
    companyName: 'GoToko',
    type: 'talent',
    title: 'Manpower for Back-end, Front-end Data Analyst, Data Engineering & DevOps Teams - GoToko',
    content: [
      'GoToko, a joint venture between the GoTo Group and Unilever, is an end-to-end e-B2B digital platform connecting brands with underserved retailers (warungs) across Indonesia.',
      'During their rapid expansion, GoTokoteamed up with G2Academy to source skilled digital talents for a few of their departments. Namely, manpower provisions for internal projects involving their Back-end, Front-end, Data Analyst and Data Engineering and DevOps teams.'
    ]
  },
  {
    id: 'gotoko',
    companyName: 'Gojek',
    type: 'project',
    title: 'GoToko App - Gojek',
    content: [
      "Gojek is one of Southeast Asia's leading multi-service tech platforms  providing access to a wide range of services including transport, payments, food delivery, logistics, and many more.",
      'With the principle of using technology to improve the lives of users, Gojek sought to expand their services with the development of GoToko.',
      'G2Academy was brought in by Gojek for the initial development phase of the GoToko App. We helped kickstart this entire project, making crucial decisions and contributions in the development process. This app is used by shop owners/ small retailers to purchase things such as food, groceries, drinks, household supplies, etc. Users can locate the status of their purchased item. Also, there is a history from the previous purchase transactions and a tempo feature with certain conditions.'
    ]
  },
  {
    id: 'bestagro',
    companyName: 'Best Agro',
    type: 'training',
    title: 'Artificial Intelligence & Machine Learning Training - Best Agro International',
    content: [
      'Best Agro International is one of the biggest palm plantation companies in Indonesia. With environmental sustainability being a main business priority, they continuously strive to incorporate policies and best practices to achieve sustainable palm oil management.',
      'As a corporate training partner, G2Academy provided Best Agro International with a learning journey tailored to enrich their developers with practical knowledge of artificial intelligence and machine learning.'
    ]
  },
  {
    id: 'bestagro',
    companyName: 'Best Agro',
    type: 'project',
    title: 'Deep Learning Based Oil Palm Tree Detection Project - Best Agro International',
    content: [
      'Best Agro International is known as one of the leading palm plantation companies in Indonesia. Dedicated to environmental sustainability, they continuously strive to incorporate policies and best practices to achieve sustainable palm oil management.',
      'Aligned with this motive, Best Agro International collaborated with G2Academy to develop a system that is able to detect the level of readiness for harvesting palm oil  using AI and Machine Learning.'
    ]
  },
  {
    id: 'smartfren',
    companyName: 'Smartfren',
    type: 'project',
    title: 'Treasure Hunt App - Smartfren',
    content: [
      'Smartfren is an Indonesia-based wireless network operator headquartered in Central Jakarta. They were one of the first pioneers for 4G LTE internet and VoLTE services in the region.',
      'In a technology project with Smartfren, G2Academy developed a Treasure Hunt App intended to gift Smartfren customers with rewards, daily bonuses and the opportunity to win a grand prize.'
    ]
  },
  {
    id: 'telkom',
    companyName: 'Telkom Indonesia',
    type: 'project',
    title: 'Intermoda App - Telkom Indonesia',
    content: [
      'Telkom Indonesia stands as one of the largest information & communications technology enterprises, and telecommunications network in Indonesia. The company categorizes their portfolio into 3 main digital business domains, which includes digital connectivity, digital platform and digital services.',
      'Telkom Indonesia worked together with G2Academy to create a prototype of the intermoda. Trusting in our skills and capabilities in making key contributions to the project.'
    ]
  },
  {
    id: 'prakerja',
    companyName: 'Kartu Prakerja',
    type: 'training',
    title: 'Basic Digital Skills Training - Kartu Prakerja',
    content: [
      'Kartu Prakerja is a movement by the Indonesian government founded to help citizens expand their skills and become more job-ready. It gives complimentary classes or courses intended to improve their career prospects.',
      'In this respect, G2Academy has aided the cause by offering basic digital training to any job seekers registered within the Kartu Prakerja program.'
    ]
  },
  {
    id: 'prakerja',
    companyName: 'Kartu Prakerja',
    type: 'project',
    title: 'Website - Kartu Prakerja',
    content: [
      'Kartu Prakerja is an initiative by the Indonesian government intended to help job seekers develop the capabilities needed to get hired or start a career. Providing complimentary classes or courses to improve targeted skill sets.',
      'Being involved in this process, G2Academy was trusted to build the entire Kartu Prakerja website. This digital journey through the marketplace was chosen so that user are able to easily search, compare, and evaluate classes.'
    ]
  },
  {
    id: 'softorb',
    companyName: 'Softorb',
    type: 'training',
    title: 'Golang & Front-end Web and Mobile Training - Softorb',
    content: [
      'Softorb provides IT solutions based on RFID and Smart Card technology for payment, identification, access control and many others.',
      "In line with their vision to become industry leaders, Softorb enlisted G2Academy to help level up their employees' professional growth. We curated a comprehensive learning journey with a curriculum centered around GoLang as well as Front-end Web and Mobile Development."
    ]
  },
  {
    id: 'doku',
    companyName: 'DOKU',
    type: 'talent',
    title: 'Manpower For the Quality Assurance Team - DOKU',
    content: [
      'DOKU is a payment technology company with an extensive network and a complete range of products aimed at helping businesses accelerate performance, maximize local potential and optimize benefits.',
      "G2Academy supported DOKU in sourcing manpower, consultants and experts for ongoing internal projects. Candidates recruited were mainly utlized for DOKU's Quality Assurance and Back-end development teams."
    ]
  },
  {
    id: 'baf',
    companyName: 'BAF',
    type: 'training',
    title: 'Product Owner & Business Soft Skill Training - BAF',
    content: [
      "PT Bussan Auto Finance (BAF) is one of Indonesia's leading financing company focusing on new Yamaha motorcycles, used motorcycles, Sharia financing, cars, Multi-Products (electronics & home appliances), agricultural machinery, Pradana, and Adidana.",
      "Within this collaboration, BAF appointed G2Academy with the task of elevating their employees' general business soft skills and their capabilities as product owners. Both essential in improving output, productivity and workplace synergy."
    ]
  },
  {
    id: 'fif',
    companyName: 'FIF',
    type: 'talent',
    title: 'Manpower For Project Management - FIF',
    content: [
      "PT Federal International Finance (FIFGROUP) is an Indonesian company that provides conventional and sharia financing facilities. FIFGROUP's business fields mainly covers Investment Financing, Working Capital Financing, Multipurpose Financing, Operating Leases and/or fee-based activities, Sharia Financing and many more.",
      'As their teams kept growing and projects became more frequent, G2Academy became their external talent pipeline that aided in the sourcing of quality candidates for their project manager roles.'
    ]
  },
  // {
  //   id: 'accelerice',
  //   companyName: 'Accelerice',
  //   type: 'platform',
  //   title: 'Learning Management System - Accelerice',
  //   content: [
  //     "Accelerice is an Indonesian-based food accelerator built to empower and scale up small to medium businesses. This company is Indonesia's very first food innovation and knowledge hub for aspiring entrepreneurs and individuals.",
  //     "Accelerice now uses G2Academy's Learning Management System as a platform to manage and implement their Kartu Prakerja classes. Making the process for both Accelerice and learners more effective as a whole."
  //   ]
  // },
  {
    id: 'manulife',
    companyName: 'Manulife',
    type: 'training',
    title: 'Data Science Training - Manulife',
    content: [
      'Manulife is a leading international financial services group that provides financial advice, insurance, as well as wealth and asset management solutions for individuals, groups and institutions.',
      "G2Academy collaborated with Manulife as a corporate training partner that developed an extensive training curriculum aimed at improving their employees' skills in Data Science."
    ]
  },
  {
    id: 'indosat',
    companyName: 'Indosat',
    type: 'training',
    title: 'Hackathon - Indosat',
    content: [
      "Indosat is one of Indonesia's largest telecommunication networks and services providers. They are known to offer (prepaid and postpaid) mobile phone services, fixed voice (including IDD) services, fixed wireless and fixed telephone services.",
      'G2Academy was involved in a Hackathon with Indosat, which is an incubation event for collaborative training and mentoring between the two companies.'
    ]
  },
  {
    id: 'travymeal',
    companyName: 'TravyMeal',
    type: 'project',
    title: 'App Development - TravyMeal',
    content: [
      'Travymeal was created for food delivery service around the airport area. Users are presented with diverse food options to choose from as the company had partnered with various well-known merchants. Users can even get information about flights, shopping locations, facilities, and other airport related information.',
      "G2Academy was tasked to construct TravyMeal's main application. We were heavily involved in every single process to ensure a smooth and well-received implementation."
    ]
  },
  {
    id: 'gorrywell',
    companyName: 'Gorry',
    type: 'project',
    title: 'App Development - Gorry',
    content: [
      'Gorry is a data-driven wellness platform, and leading clinical meals provider through Gorry Gourmet and GorryWell. They are an online food catering startup that structures meals for healthy lifestyle enthusiasts and chronic disease patients.',
      'G2Academy was given lead in developing their health and wellness app. Creating features that allowed users to pick their specified meal plans and schedule its delivery right to their doorsteps.'
    ]
  },
  {
    id: 'gradana',
    companyName: 'Gradana',
    type: 'project',
    title: 'Website Development - Gradana',
    content: [
      "Gradana is the pioneer of property fintech in Southeast Asia. They established a presence in Indonesia's fintech industry with financing products like GraSewa for rent, GraRenov for renovation and GraDP for mortgage down payment. Fully licensed by OJK and Kominfo, Gradana offers secured loans with property as collateral.",
      'To initiate a strong online presence, G2Academy helped Gradana in planning and building a website that could effectively facilitate their peer-to-peer lending activities.'
    ]
  },
  {
    id: 'antv',
    companyName: 'ANTV',
    type: 'training',
    title: 'React Native Training - ANTV',
    content: [
      'ANTV is a popular Indonesian free-to-air television network based in South Jakarta. As a network, ANTV is supported by 44 transmission stations, reaching more than 160 cities and 146 million people.',
      'G2Academy has supported ANTV as a corporate training partner, namely in producing a curriculum targeting the development of React Native skill sets. Incorporating best practices relevant within the industry.'
    ]
  },
  {
    id: 'nexsoft',
    companyName: 'Nexsoft',
    type: 'training',
    title: 'Full-stack Developer Bootcamp - Nexsoft',
    content: [
      'Nexsoft provides technology products and service solutions designed for ease-of-use based on Indonesian business practices. These solutions can be deployed in various sectors and industries, with the objective of enhancing the efficiencies of business operations.',
      "Nexsoft's ongoing technology growth prompted the need to undergo regular corporate training for their employees. As their partner, G2Academy has successfully implemented multiple rounds of bootcamp programs to ensure their developers are equipped with the most relevant Full-stack skills in the industry."
    ]
  },
  {
    id: 'cpprima',
    companyName: 'CP Prima Tbk',
    type: 'training',
    title: 'Software Development Life Cycle Training - CP Prima Tbk',
    content: [
      'CP Prima Tbk is a leading Indonesian aquaculture company that produces and sells feed, fry, pet food, probiotics, shrimp products along with processed food products for domestic and export markets.',
      "The company relied on G2Academy to prepare and carry out a training program to improve their employee's working knowledge of the Software Development Life Cycle."
    ]
  },
  {
    id: 'ptpp',
    companyName: 'PT PP (Persero) Tbk',
    type: 'training',
    title: 'Programming 101 Training - PT PP (Persero) Tbk',
    content: [
      'PT PP (Persero) Tbk is a prominent player in the national construction business and has secured key projects across Indonesia through its seven business segments. As one of the State-Owned (BUMN) construction companies in the region, they operate in several business lines, including construction service, EPC (Power Plant, Mining), Property (Commercial, Residential, Hotel), Investment (Power Plant & Infrastructure) and others (Precast, stake piling, etc).',
      'With the increasing need for programmers to constantly stay up to date, G2Academy paired up with PT PP (Persero) Tbk as a corporate training partner to help initiate a Programming 101 training scheme for their employees. Ensuring that they keep improving and growing professionally.'
    ]
  }
]
export default portfolio
